import { useState, useEffect } from 'react';
import * as Cookies from 'js-cookie';
export const protocol = process.env.API_PROTOCOL;
const credentials = protocol == 'https' ? 'same-origin' : 'include';
export let host = process.env.API_HOST;
if (host === 'localhost:8000') {
  host = `${window.location.hostname}:8000`;
}
const apiPath = `${protocol}://${host}/api/`;
const contentPath = `${protocol}://${host}/api/content/`;
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};
const baseOptions = {
  headers: headers,
  credentials: credentials,
  cors: 'cors'
};
export const fetcher = async (path, method, data) => {
  let url = `${apiPath}${path}`;
  let options = {
    ...baseOptions,
    method: method.toUpperCase() || 'GET'
  };
  if (method !== 'get') {
    if (data) {
      options.body = JSON.stringify(data);
    }
  }
  options.headers['X-CSRFToken'] = Cookies.get('csrftoken');
  try {
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};
export const get = path => {
  return fetcher(path, 'get');
};
export const post = (path, data) => {
  return fetcher(path, 'post', data);
};
export const put = (path, data) => {
  return fetcher(path, 'put', data);
};
export const patch = (path, data) => {
  return fetcher(path, 'PATCH', data);
};
export const destroy = path => {
  return fetcher(path, 'delete');
};
const useFetchingBase = function (methodName, url) {
  let fetchData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [shouldSend, setShouldSend] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [ok, setOk] = useState(null);
  const clear = () => {
    setLoading(false);
    setData([]);
    setErrors({});
    setShouldSend(false);
    setStatusCode(null);
    setOk(null);
  };
  const send = () => {
    setShouldSend(true);
  };
  useEffect(() => {
    if (shouldSend) {
      setLoading(true);
      setStatusCode(null);
      setOk(null);
      if (methodName !== 'GET') {
        headers['X-CSRFToken'] = Cookies.get('csrftoken');
      }
      const fetcher = async () => {
        const init = {
          method: methodName,
          headers: headers,
          mode: 'cors',
          credentials: credentials
        };
        if (fetchData) {
          init.body = JSON.stringify(fetchData);
        }
        try {
          const response = await fetch(url, init);
          if (response.headers.get('content-type')) {
            const fetchedData = await response.json();
            if (response.ok) {
              setData(fetchedData);
              setErrors({});
            } else {
              setErrors(fetchedData);
            }
          }
          setStatusCode(response.status);
          setOk(response.ok);
        } catch (err) {
          if (err instanceof TypeError) {
            setOk(false);
            setStatusCode(418);
            setErrors({
              network: 'Could not connect to server. Check your connection.'
            });
            console.error(err);
          } else {
            throw err;
          }
        } finally {
          setLoading(false);
          setShouldSend(false);
        }
      };
      fetcher();
    }
  }, [shouldSend]);
  return {
    data: data,
    loading: loading,
    statusCode: statusCode,
    ok: ok,
    send: send,
    setData: setData,
    clear: clear,
    errors: errors
  };
};
export const useGetContent = path => useFetchingBase('GET', contentPath + path);
export const useGet = path => useFetchingBase('GET', apiPath + path);
export const usePost = (path, data) => useFetchingBase('POST', apiPath + path, data);
export const usePut = (path, data) => useFetchingBase('PUT', apiPath, +path, data);
export const usePatch = (path, data) => useFetchingBase('PATCH', apiPath + path, data);
export const useDelete = path => useFetchingBase('DELETE', apiPath + path);
export const useAuth = () => {
  const [action, setAction] = useState('');
  const [formData, setFormData] = useState({});
  const [loggedIn, setLoggedIn] = useState(true);
  const url = `${apiPath}auth/`;
  const {
    data,
    statusCode,
    ok,
    loading,
    send,
    clear,
    errors
  } = useFetchingBase('POST', `${url}${action}/`, formData);
  useEffect(() => {
    if (ok && action === 'login') {
      setLoggedIn(true);
    } else if (statusCode === 401 || action === 'logout' && ok) {
      setLoggedIn(false);
    }
    if (ok === false) {
      setAction('');
      setFormData({});
    }
  }, [ok, statusCode]);
  useEffect(() => {
    if (action) {
      if (action === 'login' && formData.email || action === 'logout') {
        send();
      }
    }
  }, [action]);
  const login = (email, password) => {
    setAction('login');
    setFormData({
      email: email,
      password: password
    });
  };
  const logout = () => {
    setAction('logout');
    setFormData({});
    setLoggedIn(false);
    clear();
  };
  return {
    loading: loading,
    loggedIn: loggedIn,
    statusCode: statusCode,
    setLoggedIn: setLoggedIn,
    login: login,
    logout: logout,
    data: data,
    errors: errors
  };
};