import { createTheme } from '@mui/material/styles';
const displayFontDict = {
  fontFamily: 'Raleway Variable, Roboto, Open Sans, sans-serif',
  fontOpticalSizing: 'auto'
};
const theme = createTheme({
  palette: {
    primary: {
      // main: '#80deea',
      main: '#3b4a5d'
    },
    secondary: {
      // main: '#00796b',
      main: '#bbab9a'
    }
  },
  typography: {
    h1: displayFontDict,
    h2: displayFontDict,
    h3: displayFontDict,
    h4: displayFontDict,
    h5: displayFontDict,
    h6: displayFontDict,
    subtitle1: displayFontDict,
    subtitle2: displayFontDict
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.3)'
        }
      }
    }
  }
});
export default theme;