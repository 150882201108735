import { useState, useEffect } from 'react';
const useBanquest = () => {
  const [ready, setReady] = useState(false);
  const [cardForm, setCardForm] = useState(null);
  const exists = document.getElementById('banquest_script');
  useEffect(() => {
    if (exists) {
      setReady(true);
    } else {
      let script = document.createElement('script');
      script.src = process.env.PAYMENT_TOKENIZATION_ENDPOINT;
      script.id = 'banquest_script';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setReady(true);
    }
  }, []);
  useEffect(() => {
    if (ready) {
      const hostedTokenization = new window.HostedTokenization(process.env.PAYMENT_PUBLIC_KEY);
      setCardForm(hostedTokenization.create('card-form'));
    }
    return () => cardForm && cardForm.destroy();
  }, [ready]);
  return cardForm;
};
export default useBanquest;